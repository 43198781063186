import React, { Component } from "react";
import "../WorkReuse.css";

export class WorkReuse extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="workcomponent" id={this.props.id2}>
        <h5 id="workcomponenttitle">{this.props.title}</h5>
        <img id={this.props.id} src={this.props.image}></img>
        <a href={this.props.destination} id="viewprojectsa">
          <div id="viewprojects">View</div>
        </a>
      </div>
    );
  }
}

export default WorkReuse;
