import React, { Component } from "react";
import NavBar from "./WorkNavBar";
import cheezit from "../Assets/cheezit.jpg";
import kaptir from "../Assets/kaptir.jpg";
import ps5 from "../Assets/ninjaps5.jpeg";
import othello from "../Assets/othello.jpg";
import appearance from "../Assets/appearance.jpg";
import illustration from "../Assets/illustration.jpg";
import "../Concept.css";
export class Other extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <NavBar />
        <div id="titlenav">
          <h1 id="pjcttitle">{this.props.title}</h1>
        </div>
        <div id="conceptprojects">
          <img src={cheezit} className="conceptimage"></img>
          <img src={kaptir} className="conceptimage"></img>
          <img src={othello} className="conceptimage"></img>
          <img src={appearance} className="conceptimage"></img>
          <img src={ps5} className="conceptimage"></img>
          <img src={illustration} className="conceptimage"></img>
        </div>
      </div>
    );
  }
}

export default Other;
