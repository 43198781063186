import React, { Component } from "react";
import NavBar from "./WorkNavBar";
import colazo from "../Assets/colazo.jpg";
import naruto from "../Assets/naruto.jpg";
import tyty from "../Assets/tyty.jpg";
import heaven from "../Assets/heaven.jpg";
import baseball from "../Assets/baseball.jpg";
import fishy from "../Assets/fishy.jpg";
import fishy2 from "../Assets/fishy2.jpg";
import prism from "../Assets/prism.jpg";
import panels from "../Assets/panels.jpg";
import yessir from "../Assets/yessir.jpeg";
import "../Design.css";

export class Design extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <NavBar />
        <div id="titlenav">
          <h1 id="pjcttitle">{this.props.title}</h1>
        </div>
        <div id="designprojects">
          <img className="designimage" src={colazo}></img>
          <img className="designimage" src={heaven}></img>
          <img className="designimage" src={yessir}></img>
          <img className="designimage" src={naruto}></img>
          <img className="designimage" src={tyty}></img>
          <img className="designimage" src={baseball}></img>
          <img className="designimage" src={fishy}></img>
          <img className="designimage" src={fishy2}></img>
          <img className="designimage" src={prism}></img>
          <img className="designimage" id="panels" src={panels}></img>
        </div>
      </div>
    );
  }
}

export default Design;
