import React from "react";
import "../AboutMe.css";
import pdf from "../Assets/Resume.pdf";

function AboutMe() {
  return (
    <div>
      <h1 id="aboutme">About Me</h1>
      <h5 id="paragraph">
        Hi, my name is Jonah Zadik. I'm from Toronto, Canada, and I'm currently
        studying Interactive Media and Seneca Polytechnic. I have been designing
        since 2020, with new experience as a Full-Stack developer. Ever since I
        was a young kid, I've been interested in Art and its related fields.
        After taking a Full-Stack bootcamp, I found that designing through
        creating webpages is truly what I enjoy most.
      </h5>
      <div id="buttonwrapper">
        <a href={pdf} target="_blank">
          <button class="my-custom-button">View my resume</button>
        </a>
      </div>
    </div>
  );
}

export default AboutMe;
