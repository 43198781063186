import React from "react";
import "../App.css";
import NavBar from "./WorkNavBar";

function Unavailable() {
  return (
    <div>
      <NavBar />
      <div id="unavailable">
        <h1 id="unavailabletext">Page is currently unavailable</h1>
        <h5 id="sorry">Sorry for the inconvenience</h5>
      </div>
    </div>
  );
}

export default Unavailable;
