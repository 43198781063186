import React from "react";
import "../MyWork.css";
import WorkReuse from "./WorkReuse";
import webdev from "../Assets/webdev.jpg";
import figma from "../Assets/figma.jpg";
import designs from "../Assets/designs.jpg";
import other from "../Assets/otherprojects.jpg";

function MyWork() {
  return (
    <div id="myworkdivwrapper">
      <div id="myworkdiv">
        <WorkReuse
          image={designs}
          id="webdev"
          title="Digital Designs"
          destination="projects/digitaldesigns"
        />
        <WorkReuse
          image={webdev}
          id="webdev"
          title="Web Dev Projects"
          destination="projects/webdev"
        />
        <WorkReuse
          image={other}
          id="webdev"
          title="Concept Art"
          destination="projects/conceptart"
        />
        <WorkReuse
          image={figma}
          id="webdev"
          id2="other"
          title="Figma Prototypes"
          destination="projects/figma"
        />
      </div>
    </div>
  );
}

export default MyWork;
