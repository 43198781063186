import React, { Component } from "react";
import "../Project.css";

import NavBar from "./WorkNavBar";
import Projectcomp from "./Projectcomp";
import scoop from "../Assets/scoop.jpg";
import reactcalc from "../Assets/reactcalc.jpg";
import Weather from "../Assets/weather.jpg";
import typemaster from "../Assets/typemaster.jpg";
export class Project extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <NavBar />
        <div id="titlenav">
          <h1 id="pjcttitle">{this.props.title}</h1>
        </div>
        <div id="projects">
          <Projectcomp
            image={reactcalc}
            id={"reactcalc"}
            class="extended"
            projectname="React Calculator"
            link="https://reactcalculatorjz.netlify.app/"
          />
          <Projectcomp
            image={Weather}
            id={"weather"}
            projectname="Weather App"
            link="https://weatherappjonahz.netlify.app/"
          />
          <Projectcomp
            image={typemaster}
            id={"typemaster"}
            class="typemaster"
            projectname="Typing Test"
            link="https://typingtestjz.netlify.app/"
          />
        </div>
      </div>
    );
  }
}

export default Project;
