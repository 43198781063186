import React from "react";
import "../NavBar2.css";
import logo from "../Assets/logo.png";
import github from "../Assets/github.png";
import email from "../Assets/email.png";
import "../App.css";
import home from "../Assets/home.png";

function WorkNavBar() {
  return (
    <nav id="nav2">
      <a href="/">
        <img id="homeimage" src={home}></img>
      </a>
      <a href="/" id="logo2">
        <img src={logo} id="logoimg2"></img>
      </a>
      <div id="icons2">
        <a
          href="https://github.com/jonahzadik?tab=repositories"
          target={"_blank"}
        >
          <img src={github} id="github2"></img>
        </a>
        <a href="mailto:j2zadik2004@gmail.com" target={"_blank"}>
          <img src={email} id="github2"></img>
        </a>
      </div>
    </nav>
  );
}

export default WorkNavBar;
