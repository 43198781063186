import React from "react";
import "../Navbar.css";
import logo from "../Assets/logo.png";
import github from "../Assets/github.png";
import email from "../Assets/email.png";
import "../App.css";

function Navbar() {
  return (
    <nav id="nav">
      <div id="links">
        <a href="#home" className="btn">
          Home
        </a>
        <a href="#mywork" className="btn">
          My Work
        </a>
        <a href="#aboutme" className="btn">
          About Me
        </a>
      </div>
      <a href="/" id="logo">
        <img src={logo} id="logoimg"></img>
      </a>
      <div id="icons">
        <a
          href="https://github.com/jonahzadik?tab=repositories"
          target={"_blank"}
        >
          <img src={github} id="github"></img>
        </a>
        <a href="mailto:jonah.zadik@hotmail.com" target={"_blank"}>
          <img src={email} id="github"></img>
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
