import React, { Component } from "react";
import "../Projectcomp.css";

export class Projectcomp extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="projectdiv" className={this.props.class}>
        <h1 id="projecttitle">{this.props.projectname}</h1>
        <img id="projectimage" src={this.props.image}></img>
        <a href={this.props.link} target="_blank">
          <button class="my-custom-button2">View Full Page</button>
        </a>
      </div>
    );
  }
}

export default Projectcomp;
