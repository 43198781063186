import React, { Component } from "react";
import NavBar from "./WorkNavBar";
import essayhub from "../Assets/Essayhub.jpg";
import "../Figma.css";
import nike from "../Assets/Nike.jpg";
import scoopfigma from "../Assets/scoopfigma.jpg";

export class Figma extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <NavBar />
        <div id="titlenav">
          <h1 id="pjcttitle">{this.props.title}</h1>
        </div>
        <div id="figmaprojects">
          <img id="essayhub" src={essayhub}></img>
          <img id="nike" src={nike}></img>
          <img id="scoopfigma" src={scoopfigma}></img>
        </div>
      </div>
    );
  }
}

export default Figma;
