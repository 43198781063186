import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import MyWork from "./Components/MyWork";
import AboutMe from "./Components/AboutMe";
import Footer from "./Components/Footer";
import Webdevproject from "./Components/Webdevproject";
import Figma from "./Components/Figma";
import Design from "./Components/Design";
import Concept from "./Components/Concept";
import Unavailable from "./Components/Unavailable";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <MyWork />
                <AboutMe />
                <Footer />
              </>
            }
          />
          <Route
            path="projects/webdev"
            element={
              <>
                <Webdevproject title="Web Developement Projects" />
              </>
            }
          />
          <Route
            path="projects/figma"
            element={
              <>
                <Figma title="Figma Prototypes" />
              </>
            }
          />
          <Route
            path="projects/digitaldesigns"
            element={
              <>
                <Design title="Digital Designs" />
              </>
            }
          />
          <Route
            path="projects/conceptart"
            element={
              <>
                <Concept title="Concept Art" />
              </>
            }
          />
          <Route
            path="/Unavailable"
            element={
              <>
                <Unavailable />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
