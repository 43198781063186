import React from "react";
import "../Footer.css";

function Footer() {
  return (
    <div id="wrapperdiv">
      <h1 id="footer">Copyright © 2024 Jonah Zadik. All Rights Reserved.</h1>
    </div>
  );
}

export default Footer;
