import React from "react";
import "../Home.css";
import "../App.css";
import headshot from "../Assets/headshot.png";

function Home() {
  return (
    <div>
      <h4 id="home">.</h4>
      <h4 className="dot">.</h4>
      <h4>.</h4>
      <h4>.</h4>
      <div id="homedivwrapper">
        <div id="homediv">
          <div>
            <h1 id="title">
              Hey &#128075;, <br></br>
              I’m Jonah Zadik!
            </h1>
            <h3>
              Graphic Designer with 4 Years of Experience, Certified <br></br>
              Web Developer, Passionate about 3D, Photography, <br></br>
              and DJing, Based in Toronto.
            </h3>
          </div>
          <div id="headshotdiv">
            <img id="headshot" src={headshot}></img>
          </div>
        </div>
      </div>
      <div className="scrolldiv" id="mywork">
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
        <h1>My Work</h1>
      </div>
    </div>
  );
}

export default Home;
